<template>
  <v-row v-if="isVisible" dense class="mx-1">
    <v-col cols="6">
      <v-select
        :label="$t('Edge type')"
        :items="edgeTypes"
        v-model="selectedEdgeType"
        @input="changeEdgeType"
        hide-details
      ></v-select>
    </v-col>
    <v-col cols="6">
      <v-select
        :label="$t('Edge thickness')"
        :items="edgeThickness"
        v-model="selectedEdgeThickness"
        @input="changeEdgeThickness"
        hide-details
      ></v-select>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped></style>

<script>
import { mapGetters } from 'vuex';
import constants from '~/shared/constants';
export default {
  name: 'EdgeTypeSelector',

  props: ['draw', 'map', 'undoCount'],

  mounted() {
    this.map.on('draw.create', (e) => {
      const edges = e.features.filter(
        (feature) => feature.properties._type === constants.FEATURE_TYPES.EDGE
      );

      this.updateEdges(edges, false);
    }),
      this.map.on('draw.selectionchange', () => {
        this.refresh();
      });
  },

  data() {
    return {
      selectedEdgeType: constants.EDGE_TYPES.WALL,
      selectedEdgeThickness: constants.EDGE_THICKNESS.THICK,
      selectedEdges: [],
      isVisible: false
    };
  },
  computed: {
    ...mapGetters('status', ['currentDrawMode']),
    edgeTypes() {
      return [
        { value: constants.EDGE_TYPES.WALL, text: this.$t('wall') },
        { value: constants.EDGE_TYPES.DOOR, text: this.$t('door') },
        { value: constants.EDGE_TYPES.WINDOW, text: this.$t('window') }
      ];
    },
    edgeThickness() {
      let items = [
        { value: constants.EDGE_THICKNESS.THICK, text: this.$t('thick') },
        { value: constants.EDGE_THICKNESS.THIN, text: this.$t('thin') }
      ];

      if (this.selectedEdgeType === constants.EDGE_TYPES.WALL) {
        items.push({ value: constants.EDGE_THICKNESS.LOGICAL, text: this.$t('logical') });
      }

      return items;
    }
  },
  watch: {
    currentDrawMode() {
      if (this.currentDrawMode === constants.DRAW_MODES.EDGE) {
        this.isVisible = true;
        if (!this.selectedEdgeType) {
          this.selectedEdgeType = constants.EDGE_TYPES.WALL;
        }
        if (!this.selectedEdgeThickness) {
          this.selectedEdgeThickness = constants.EDGE_THICKNESS.THICK;
        }
      } else {
        this.isVisible = false;
      }
    },

    undoCount() {
      this.refresh();
    }
  },
  methods: {
    refresh() {
      const { features } = this.draw.getSelected();
      this.selectedEdges = features.filter(
        (feature) => feature.properties._type === constants.FEATURE_TYPES.EDGE
      );

      if (this.currentDrawMode === constants.DRAW_MODES.EDGE) {
        return;
      }

      this.isVisible = this.selectedEdges.length && this.selectedEdges.length === features.length;

      if (this.isVisible) {
        this.selectedEdgeType = '';
        const typeOfFirstEdge = this.selectedEdges[0].properties.type;
        if (this.selectedEdges.every((edge) => edge.properties.type === typeOfFirstEdge)) {
          this.selectedEdgeType = typeOfFirstEdge;
        }

        this.selectedEdgeThickness = '';
        const thicknessOfFirstEdge = this.selectedEdges[0].properties.thickness;
        if (
          this.selectedEdges.every((edge) => edge.properties.thickness === thicknessOfFirstEdge)
        ) {
          this.selectedEdgeThickness = thicknessOfFirstEdge;
        }
      }
    },

    changeEdgeType() {
      if (
        this.selectedEdgeType !== constants.EDGE_TYPES.WALL &&
        this.selectedEdgeThickness === constants.EDGE_THICKNESS.LOGICAL
      ) {
        this.selectedEdgeThickness = constants.EDGE_THICKNESS.THICK;
      }

      if (this.selectedEdges && this.selectedEdges.length) {
        this.updateEdges(this.selectedEdges);
        this.$emit('change', constants.OPERATIONS.FLOORPLAN.EDGE_TYPE);
      }
    },
    changeEdgeThickness() {
      if (this.selectedEdges && this.selectedEdges.length) {
        this.updateEdges(this.selectedEdges);
        this.$emit('change', constants.OPERATIONS.FLOORPLAN.EDGE_THICKNESS);
      }
    },

    updateEdges(edges) {
      edges.forEach((edge) => {
        if (this.selectedEdgeType) {
          this.draw.setFeatureProperty(edge.id, 'type', this.selectedEdgeType);
        }
        if (this.selectedEdgeThickness) {
          this.draw.setFeatureProperty(edge.id, 'thickness', this.selectedEdgeThickness);
        }
      });
      this.draw.set(this.draw.getAll());
    }
  }
};
</script>
